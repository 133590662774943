import Vue from "vue";

import App from "./App.vue";
import router from "./router";

import "@/styles/main.scss";
import "@/components";

import "@/icons";

import VueCarousel from "vue-carousel";

import VTooltip from "v-tooltip";
Vue.use(VTooltip, {
  defaultHideOnTargetClick: false,
  defaultOffset: 5,
  defaultDelay: 300,
});

Vue.use(VueCarousel);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
