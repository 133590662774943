<template>
  <section class="section-d container">
    <div class="section-d__wave wave__top"></div>

    <div class="section-d__button-wrapper section-d__button-wrapper-top">
      <ButtonComponent
        :label="'BYGG SJOKOLADEHUS'"
        tag="button"
        @click="redirect(['BuildYourHouse'], { scrollTo: 0 })"
        theme="primary"
      />
      <ButtonComponent
        :label="'KONKURRANSE'"
        tag="button"
        @click="redirect(['Competition'], { scrollTo: 0 })"
        theme="primary"
      />
    </div>

    <div class="section-d__content">
      <div class="section-d__content__subparentA">
        <img
          class="section-d__content__main-image"
          src="@/assets/Elements/Postcard_02.png"
        />
      </div>

      <div class="section-d__content__subparentB">
        <h1>BYGGEGLEDE</h1>

        <p>
          Siden 2016 har vi bygget en ny tradisjon med sjokoladehus, bestående
          av folkekjære produkter. I år forsterker vi byggegleden med en helt ny
          laftehytte, og håper du og dine er klare for nye, tradisjonsrike
          utfordringer. God jul!
        </p>

        <div class="section-d__content__subparentB__image"></div>

        <img
          class="section-d__content__subparentB__image"
          src="@/assets/Elements/Freia-logo-red.svg"
        />
      </div>
    </div>

    <img
      class="section-d__content__subparentB__image__mobile"
      src="@/assets/Elements/Freia-logo-red.svg"
    />
  </section>
</template>
<script>
import { redirect as Redirect } from "@/utils";

export default {
  methods: {
    redirect(...target) {
      Redirect(...target, this)();
    },
  },
};
</script>
<style lang="scss" scoped>
h1 {
  margin-top: 1.5rem;
  margin-bottom: 0;

  @include respond-below("phone") {
    margin: 0;
  }
}

.section-d {
  position: relative;
  padding-bottom: 1rem;

  @include respond-below("tablet") {
    padding-bottom: 7rem;
  }

  &__wave {
    background-image: url("@/assets/BG/wave_beige_desktop_top.svg");

    @include respond-below("tablet") {
      background-image: url("@/assets/BG/wave_beige_mobile_top.svg");
    }
  }

  &__button-wrapper {
    &-top {
      transform: translateY(-50%);
    }

    display: flex;
    justify-content: center;
    gap: 10%;

    @include respond-below("tablet") {
      position: absolute;

      justify-content: space-around;
      align-items: center;
      gap: 1rem;
      padding-inline: 1.5rem;
      flex-direction: column;
      padding-top: 1.5rem;
      padding-bottom: 1rem;
      left: 50%;
      transform: translate(-50%, -100%);
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 0 1rem 4rem 1rem;

    &__main-image {
      width: 100%;
      max-width: 644px;

      @include respond-below("phone") {
        width: 100%;
        gap: 1rem;
      }
    }

    h1 {
      text-align: start;

      @include respond-below("tablet") {
        text-align: center;
      }
    }

    p {
      margin: 0;
      margin-top: 1rem;
      text-align: start;
    }

    @include respond-below("tablet") {
      gap: 0;
      flex-direction: column-reverse;
      margin-bottom: 0;
      padding: 2rem 1rem 1rem 1rem;

      p {
        text-align: center;
      }
    }

    @include respond-below("phone") {
      padding-top: 3rem;
    }

    &__subparentA {
      width: 50%;
      max-width: 750px;

      @include respond-below("tablet") {
        width: 100%;
      }
    }

    &__subparentB {
      width: 50%;
      max-width: 750px;
      display: flex;
      flex-direction: column;
      padding-left: 3rem;

      @include respond-below("tablet") {
        width: 100%;
        padding-left: 0rem;
      }

      &__image {
        width: 105px;
        align-self: flex-end;

        @include respond-below("tablet") {
          width: 75px;
        }

        @include respond-below("tablet") {
          margin-top: 1rem;
          align-self: center;
          display: none;
        }

        &__mobile {
          @include respond-above("tablet") {
            display: none;
          }

          @include respond-below("tablet") {
            width: 75px;
            height: 75px;
          }

          @include respond-below("phone") {
            width: 75px;
            height: 75px;
          }
        }
      }
    }
  }
}
</style>
