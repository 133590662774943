import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import Campaign from "@/views/Campaign.vue";

Vue.use(VueMeta);
Vue.use(VueRouter);

function getRoutes() {
  return [
    {
      path: "/",
      name: "Campaign",
      component: Campaign,
      props: true,
    },
    {
      path: "/bygg-sjokoladehus",
      name: "BuildYourHouse",
      component: () => import("@/views/BuildYourHouse.vue"),
    },
    {
      path: "/konkurranse",
      name: "Competition",
      component: () => import("@/views/Competition.vue"),
    },
    {
      path: "/cookies",
      name: "Cookies",
      component: () => import("@/views/Cookies.vue"),
    },
    {
      path: "/hus/:id",
      name: "Hus",
      component: () => import("@/views/Hus.vue"),
    },
    {
      path: "/q&a",
      name: "QA",
      component: () => import("@/views/QA.vue"),
    },
    {
      path: "/terms",
      name: "Terms",
      component: () => import("@/views/Terms.vue"),
    },
    {
      path: "/thank-you",
      name: "ThankYou",
      component: () => import("@/views/ThankYou.vue"),
    },
    {
      path: "/starting-soon",
      name: "StartingSoon",
      component: () => import("@/views/StartingSoon.vue"),
    },
    {
      path: "/campaign-ended",
      name: "CampaignEnded",
      component: () => import("@/views/CampaignEnded.vue"),
    },
    {
      path: "/error",
      name: "Error",
      component: () => import("@/views/Error.vue"),
    },
    {
      path: "/hus/5/kutt",
      name: "Kutt",
      component: () => import("@/views/Kutt.vue"),
    },
    {
      path: "*",
      redirect: {
        name: "Error",
      },
    },
  ];
}

const routes = getRoutes();

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!to.hash) return { x: 0, y: 0 };
  },
});

export default router;
