import Vue from 'vue'

import { ButtonComponent, setup as ButtonSetup } from 'vue-elder-button'
ButtonSetup({
  type: 'button',
  labels: {
    loading: 'Loading...',
    success: 'Completed',
    confirm: 'Are you sure?',
    error: 'Something went wrong',
  },
})
Vue.component('ButtonComponent', ButtonComponent)

import { DropdownComponent } from 'vue-elder-dropdown'
Vue.component('DropdownComponent', DropdownComponent)
