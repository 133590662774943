<template>
  <section class="section-b container">
    <div class="section-b__wave wave__top"></div>

    <div class="section-b__content">
      <div class="section-b__content__subparentB">
        <h1>VINN SJOKOLADELANDSBY</h1>

        <p>
          Premien i årets store julekonkurranse er verdens kuleste byggesett,
          laget av verdens beste sjokolade. Nå kan du vinne byggesettet for
          Freia sjokoladelandsby! Trykk på lenken, registrer deg og bli med i
          trekningen av årets mest smakfulle premie!
        </p>
        <div class="section-b__content__button-parent">
          <ButtonComponent
            :label="'DELTA I KONKURRANSEN'"
            tag="button"
            @click="redirect(['Competition'], { scrollTo: 0 })"
            theme="primary"
          />
        </div>
      </div>

      <div class="section-b__content__subparentA">
        <img
          class="section-b__content__main-image"
          src="@/assets/Husene/Eske_main.png"
        />
      </div>

      <div class="section-b__content__button-parent-mobile">
        <ButtonComponent
          :label="'DELTA I KONKURRANSEN'"
          tag="button"
          @click="redirect(['Competition'], { scrollTo: 0 })"
          theme="primary"
        />
      </div>
    </div>
  </section>
</template>
<script>
import { redirect as Redirect } from "@/utils";

export default {
  methods: {
    redirect(...target) {
      Redirect(...target, this)();
    },
  },
};
</script>
<style lang="scss" scoped>
h1 {
  margin-top: 1.5rem;
  margin-bottom: 0;

  @include respond-below("phone") {
    margin: 0;
  }
}

.section-b {
  padding-bottom: 1rem;
  position: relative;
  background-color: var(--secondary);

  @include respond-below("tablet") {
    padding-bottom: 7rem;
  }

  &__wave {
    background-image: url("@/assets/BG/wave_yellow_desktop_top.svg");

    @include respond-below("tablet") {
      background-image: url("@/assets/BG/wave_yellow_mobile_top.svg");
    }
  }

  &__button-wrapper {
    &-top {
      transform: translateY(-50%);
    }

    display: flex;
    justify-content: center;
    gap: 10%;

    @include respond-below("phone") {
      justify-content: space-around;
      gap: 1rem;
      padding-inline: 1.5rem;
      flex-direction: column;
      padding-top: 1.5rem;
      padding-bottom: 1rem;
      transform: translateY(0%);
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 0 1rem 4rem 1rem;

    &__main-image {
      width: 100%;
      max-width: 644px;

      @include respond-below("tablet") {
        width: 100%;
        gap: 1rem;
      }
    }

    h1 {
      text-align: start;

      @include respond-below("tablet") {
        text-align: center;
      }
    }

    p {
      margin: 0;
      margin-top: 1rem;
      text-align: start;
    }

    &__button-parent {
      margin-top: 2rem;
      text-align: left;

      @include respond-below("tablet") {
        display: none;
      }
    }

    &__button-parent-mobile {
      margin-top: 2rem;
      text-align: left;
      display: none;

      @include respond-below("tablet") {
        display: block;
      }
    }

    @include respond-below("tablet") {
      gap: 0;
      flex-direction: column;
      margin-bottom: 0;
      padding: 0 1rem 1rem 1rem;

      p {
        text-align: center;
      }
    }

    &__subparentA {
      width: 50%;
      max-width: 750px;

      @include respond-below("tablet") {
        width: 100%;
      }
    }

    &__subparentB {
      width: 50%;
      max-width: 750px;
      display: flex;
      flex-direction: column;
      padding-left: 3rem;

      @include respond-below("tablet") {
        width: 100%;
        padding-left: 0rem;
      }

      &__image {
        width: 105px;
        align-self: flex-end;

        @include respond-below("tablet") {
          width: 75px;
        }

        @include respond-below("tablet") {
          margin-top: 1rem;
          align-self: center;
          display: none;
        }

        &__mobile {
          width: 75px;
          height: 75px;
          margin-bottom: 1rem;

          @include respond-above("tablet") {
            display: none;
          }
        }
      }
    }
  }
}
</style>
<!-- 
<template>
  <section class="section-b container">
    <div class="section-b__wave wave"></div>

    <div class="section-b__content">
      <div class="section-b__content__subdiv">
        <h2>VINN SJOKOLADELANDSBY</h2>

        <p>
          Premien i årets store julekonkurranse er verdens kuleste byggesett,
          laget av verdens beste sjokolade. Nå kan du vinne byggesettet for
          Freia sjokoladelandsby! Trykk på lenken, registrer deg og bli med i
          trekningen av årets mest smakfulle premie!
        </p>
        <div class="section-b__content__button-parent">
          <ButtonComponent
            :label="'DELTA I KONKURRANSEN'"
            tag="button"
            @click="redirect(['Competition'], { scrollTo: 0 })"
            theme="primary"
          />
        </div>
      </div>

      <img src="@/assets/Husene/Eske_main.png" />
    </div>
  </section>
</template>
<script>
import { redirect as Redirect } from "@/utils";

export default {
  methods: {
    redirect(...target) {
      Redirect(...target, this)();
    },
  },
};
</script>
<style lang="scss" scoped>
.section-b {
  position: relative;
  background-color: var(--secondary);

  &__wave {
    background-image: url("@/assets/BG/wave_yellow_desktop_top.svg");
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    padding: 0 1rem 4rem 1rem;

    @include respond-below("phone") {
      gap: 2rem;
      flex-direction: column;
    }

    img {
      width: 50%;
      max-width: 642px;

      @include respond-below("phone") {
        width: 300px;

        gap: 2rem;
      }
    }

    h2 {
      text-align: start;
    }

    p {
      max-width: 475px;
      margin: 0;
      margin-top: 1rem;
      text-align: start;
    }

    &__button-parent {
      margin-top: 2rem;
      text-align: left;

      @include respond-below("phone") {
        width: 100%;

        button {
          white-space: unset;
          min-width: 50%;
          width: 100%;
        }
      }
    }

    &__subdiv {
      width: 50%;

      @include respond-below("phone") {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          text-align: center;
        }
      }
    }
  }
}
</style> -->
