<template>
  <section class="campaign-carusel">
    <div class="campaign-carusel__wave wave__top"></div>

    <div class="campaign-carusel-wrapper container">
      <h1>HVILKET SJOKOLADEHUS VIL DU BYGGE?</h1>
      <Carousel
        :per-page="1"
        :perPageCustom="[[992, 3]]"
        :scroll-per-page="false"
        :navigation-enabled="true"
        :pagination-enabled="false"
        :navigation-next-label="nextLabel"
        :navigation-prev-label="prevLabel"
        :mouse-drag="!isDesktop"
        :touch-drag="true"
        :loop="true"
        :autoplay="true"
        :autoplayTimeout="2000"
        @page-change="onPageChange"
        :navigateTo="[navigateTo, true]"
      >
        <template v-if="isDesktop">
          <Slide
            v-for="(item, index) in houses"
            :key="index"
            :class="{
              'campaign-carusel__hero--active': index === currentSlide,
            }"
            :data-index="index + 1"
          >
            <component
              :is="'div'"
              class="campaign-carusel__hero"
              @click="redirectExternal"
            >
              <img class="campaign-carusel__hero-image" :src="item.image" />
              <div class="campaign-carusel__hero-content-wrapper">
                <div class="campaign-carusel__hero-content">
                  <h3 class="campaign-carusel__hero-name">{{ item.name }}</h3>
                </div>
              </div>
            </component>
          </Slide>
        </template>
        <template v-else>
          <slide data-index="2" @slideclick="handleSlideClick">
            <component :is="'div'" class="campaign-carusel__hero">
              <img
                class="campaign-carusel__hero-image"
                src="/assets/Husene/Laftehytta.png"
              />
              <div class="campaign-carusel__hero-content-wrapper">
                <div class="campaign-carusel__hero-content">
                  <h3 class="campaign-carusel__hero-name">LAFTEHYTTA</h3>
                </div>
              </div>
            </component>
          </slide>
          <slide data-index="3" @slideclick="handleSlideClick">
            <component :is="'div'" class="campaign-carusel__hero">
              <img
                class="campaign-carusel__hero-image"
                src="/assets/Husene/Rødlykke.png"
              />
              <div class="campaign-carusel__hero-content-wrapper">
                <div class="campaign-carusel__hero-content">
                  <h3 class="campaign-carusel__hero-name">RØD LYKKE</h3>
                </div>
              </div>
            </component></slide
          >
          <slide data-index="4" @slideclick="handleSlideClick">
            <component :is="'div'" class="campaign-carusel__hero">
              <img
                class="campaign-carusel__hero-image"
                src="/assets/Husene/Julelavvo.png"
              />
              <div class="campaign-carusel__hero-content-wrapper">
                <div class="campaign-carusel__hero-content">
                  <h3 class="campaign-carusel__hero-name">JULELAVVO</h3>
                </div>
              </div>
            </component></slide
          >
          <slide data-index="5" @slideclick="handleSlideClick">
            <component :is="'div'" class="campaign-carusel__hero">
              <img
                class="campaign-carusel__hero-image"
                src="/assets/Husene/Fabrikk.png"
              />
              <div class="campaign-carusel__hero-content-wrapper">
                <div class="campaign-carusel__hero-content">
                  <h3 class="campaign-carusel__hero-name">FREIA-FABRIKKEN</h3>
                </div>
              </div>
            </component></slide
          >
          <slide data-index="6" @slideclick="handleSlideClick">
            <component :is="'div'" class="campaign-carusel__hero">
              <img
                class="campaign-carusel__hero-image"
                src="/assets/Husene/Sjokoladehus.png"
              />
              <div class="campaign-carusel__hero-content-wrapper">
                <div class="campaign-carusel__hero-content">
                  <h3 class="campaign-carusel__hero-name">SJOKOLADEHUS</h3>
                </div>
              </div>
            </component></slide
          >
          <slide data-index="7" @slideclick="handleSlideClick">
            <component :is="'div'" class="campaign-carusel__hero">
              <img
                class="campaign-carusel__hero-image"
                src="/assets/Husene/Kvikklunsjtre.png"
              />
              <div class="campaign-carusel__hero-content-wrapper">
                <div class="campaign-carusel__hero-content">
                  <h3 class="campaign-carusel__hero-name">KVIKK LUNSJ TRE</h3>
                </div>
              </div>
            </component></slide
          >
          <slide data-index="8" @slideclick="handleSlideClick">
            <component :is="'div'" class="campaign-carusel__hero">
              <img
                class="campaign-carusel__hero-image"
                src="/assets/Husene/Stabburet.png"
              />
              <div class="campaign-carusel__hero-content-wrapper">
                <div class="campaign-carusel__hero-content">
                  <h3 class="campaign-carusel__hero-name">STABBURET</h3>
                </div>
              </div>
            </component>
          </slide>
        </template>
      </Carousel>

      <div class="campaign-carusel__button-wrapper">
        <ButtonComponent
          :label="'SE ALLE HUSENE'"
          tag="button"
          @click="redirect(['BuildYourHouse'], { scrollTo: 0 })"
          theme="primary"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { redirect as Redirect } from "@/utils";
import { Slide, Carousel } from "vue-carousel";

export default {
  props: {
    houses: Array,
    isCampaign: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      navigateTo: 0,
      currentSlide: 1,
      nextLabel:
        '<svg width="34" height="59" viewBox="0 0 34 59" fill="#fff8e2" xmlns="http://www.w3.org/2000/svg"> <path d="M32.5783 32.9716C34.4752 31.0518 34.4752 27.934 32.5783 26.0142L8.29801 1.44043C6.40111 -0.479393 3.32055 -0.479393 1.42365 1.44043C-0.473248 3.36025 -0.473248 6.47804 1.42365 8.39787L22.2744 29.5006L1.43731 50.6033C-0.45959 52.5231 -0.45959 55.6393 1.43731 57.5592C3.33421 59.479 6.41477 59.479 8.31167 57.5592L32.592 32.9854L32.5768 32.9701L32.5783 32.9716Z" fill="#3B1905"/></svg>',
      prevLabel:
        '<svg width="34" height="59" viewBox="0 0 34 59" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.4217 26.0284C-0.475194 27.9482 -0.475194 31.066 1.4217 32.9858L25.702 57.5596C27.5989 59.4794 30.6795 59.4794 32.5764 57.5596C34.4733 55.6397 34.4733 52.522 32.5764 50.6021L11.7257 29.4994L32.5627 8.39675C34.4596 6.47692 34.4596 3.36067 32.5627 1.44084C30.6658 -0.478979 27.5852 -0.478979 25.6883 1.44084L1.40805 26.0146L1.42322 26.0299L1.4217 26.0284Z" fill="#3B1905"/></svg>',
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    isDesktop() {
      return this.windowWidth > 992 ? true : false;
    },
  },

  methods: {
    redirect(...target) {
      Redirect(...target, this)();
    },
    onPageChange(currentSlide) {
      if (isNaN(currentSlide)) return;
      let val = currentSlide + 1;

      this.currentSlide = val;

      this.navigateTo = currentSlide;
    },
    redirectExternal() {
      const externalURL = `/hus/${this.currentSlide + 1}`;

      window.location.href = externalURL;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    handleSlideClick(dataset) {
      window.location.href = `/hus/${dataset.index}`;
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  components: {
    Slide,
    Carousel,
  },
};
</script>

<style lang="scss">
.campaign-carusel {
  padding: 4rem 0rem 10rem 0rem;

  position: relative;

  &__wave {
    background-image: url("@/assets/BG/wave_beige_desktop_top.svg");

    @include respond-below("tablet") {
      background-image: url("@/assets/BG/wave_beige_mobile_top.svg");
    }
  }

  .container {
    padding-inline: 3rem;

    @include respond-below("phone") {
      padding-inline: 1.5rem;
    }
  }

  h2 {
    margin-bottom: 2rem;

    @include respond-below("phone") {
      margin: 0;
    }
  }
  p {
    color: $beige;

    @include respond-below("phone") {
      margin: 0;
    }
  }

  .VueCarousel {
    max-width: 1500px;
    margin: auto;

    &-wrapper {
      padding-block: 3rem 6rem;

      @include respond-below("tablet") {
        padding-block: 0rem;
      }
    }

    &-navigation {
      &-prev {
        left: 50px !important;

        @include respond-above("phone") {
          transform: translateY(calc(-75%)) translateX(-100%);
        }

        @include respond-below("tablet") {
          display: none;
        }
      }
      &-next {
        right: 50px !important;

        @include respond-above("phone") {
          transform: translateY(calc(-75%)) translateX(100%);
        }

        @include respond-below("tablet") {
          display: none;
        }
      }

      &-button {
        &:focus {
          outline: none !important;
        }
      }
    }

    &-slide {
      transition: scale 0.3s ease-in;
      -webkit-transition: scale 0.3s ease-in;
    }
  }

  &__hero {
    color: $beige;

    position: relative;

    &--active {
      scale: 1.4;
      z-index: 2;

      @include respond-below("tablet") {
        scale: 1;
      }
    }

    &-content-wrapper {
      position: absolute;
      bottom: 0rem;
      width: 100%;

      @include respond-below("tablet") {
        bottom: 1rem;
      }
    }

    &-content {
      display: flex;
      justify-content: center;
    }

    &-image {
      object-fit: contain;
      object-position: center;

      border-radius: 100%;

      aspect-ratio: 1;
      width: 100%;
      height: 75%;

      max-width: 400px;
    }
    &-name {
      transition: font-size 0.5s ease;
      -webkit-transition: font-size 0.5s ease;
      margin: 0;
      width: initial;
      white-space: nowrap;
      padding: 0.25rem 0.5rem;
      font-size: 26px;
      font-weight: 700;

      /* @include respond-below("phone") {
            font-size: 8px;
            padding: 0 0.5rem;
          } */
    }
    &-subtitle {
      transition: font-size 0.5s ease;
      -webkit-transition: font-size 0.5s ease;

      margin: 0;
      white-space: nowrap;
      background: $beige;
      font-family: "FS ME";
      padding: 0.4em 1em;
      font-size: 14px;
      width: fit-content;
      font-weight: 400;

      // skew gap
      margin-top: -1px;
      /*
          @include respond-below("phone") {
            font-size: 3px;
          } */
    }
  }

  &__button-wrapper {
    margin-top: 1rem;

    &-top {
      transform: translateY(-50%);
    }

    &-bottom {
      transform: translateY(50%);
    }

    display: flex;
    justify-content: center;
    gap: 15%;

    @include respond-below("phone") {
      justify-content: space-around;
      gap: 1rem;
    }
  }
}
</style>
