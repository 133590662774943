<template>
  <div class="campaign">
    <!-- <div class="coming-soon">
      <h1>Kampanjen starter 1. november...</h1>
    </div> -->
    <Cover :strength="150" />
    <SectionD />
    <SectionB />
    <CampaignCarusel :houses="houses" v-if="houses" />
    <Carusel2 />
  </div>
</template>

<script>
import Form from "@/components/Form.vue";
import ChooseHouse from "@/components/ChooseHouse.vue";
import CampaignCarusel from "@/components/CampaignCarusel.vue";
import Cover from "@/components/Cover.vue";
import SectionB from "@/components/SectionB.vue";
import SectionD from "@/components/SectionD.vue";
import houses from "@/houses.json";
import Carusel2 from "@/components/Carusel2.vue";

export default {
  computed: {
    houses() {
      let data = houses;

      return data;

      // return [...data, data[0], data[1]];
    },
  },
  components: {
    Form,
    ChooseHouse,
    CampaignCarusel,
    Cover,
    SectionB,
    SectionD,
    Carusel2,
  },

  metaInfo() {
    return {
      title: "Kampanje | Freia",
    };
  },
};
</script>

<style lang="scss">
.coming-soon {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.campaign {
  text-align: center;
}
</style>
