var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"ChooseHouse container",attrs:{"id":"premie"}},[_c('div',{staticClass:"ChooseHouse__difficulty"},[_c('div',{staticClass:"ChooseHouse__difficulty-button",class:{
        'ChooseHouse__difficulty-button--selected': _vm.difficultyLevel === 'all',
      },on:{"click":function($event){_vm.difficultyLevel = 'all'}}},[_c('span',[_vm._v("ALLE")])]),_c('div',{staticClass:"ChooseHouse__difficulty-button",class:{
        'ChooseHouse__difficulty-button--selected':
          _vm.difficultyLevel === 'easy',
      },on:{"click":function($event){_vm.difficultyLevel = 'easy'}}},[_c('span',[_vm._v("LETT")])]),_c('div',{staticClass:"ChooseHouse__difficulty-button",class:{
        'ChooseHouse__difficulty-button--selected':
          _vm.difficultyLevel === 'medium',
      },on:{"click":function($event){_vm.difficultyLevel = 'medium'}}},[_c('span',[_vm._v("MIDDELS")])]),_c('div',{staticClass:"ChooseHouse__difficulty-button",class:{
        'ChooseHouse__difficulty-button--selected':
          _vm.difficultyLevel === 'hard',
      },on:{"click":function($event){_vm.difficultyLevel = 'hard'}}},[_c('span',[_vm._v("VANSKELIG")])])]),_c('div',{staticClass:"ChooseHouse__content container"},_vm._l((_vm.houses),function(item,index){return (
        _vm.difficultyLevel === 'all' || _vm.difficultyLevel === item.difficultyLevel
      )?_c('div',{key:index,staticClass:"ChooseHouse__item"},[_c('div',{staticClass:"ChooseHouse__item-image"},[_c('img',{attrs:{"src":item.img}})]),_c('div',{staticClass:"ChooseHouse__item-content"},[_c('div',{staticClass:"ChooseHouse__item-content__text"},[_c('h2',[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"ChooseHouse__item-description"},[_vm._v(_vm._s(item.description))]),_c('div',{staticClass:"ChooseHouse__points"},[_vm._m(0,true),_c('img',{attrs:{"width":"150px","src":("/assets/icons/" + (item.difficulty))}})])]),_c('ButtonComponent',{staticClass:"ChooseHouse__button",attrs:{"label":'INSTRUKSJONER',"tag":"button","theme":"primary"},on:{"click":function($event){return _vm.redirectExternal(index + 2)}}})],1)]):_vm._e()}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('strong',[_vm._v("Vanskelighetsgrad")])])}]

export { render, staticRenderFns }