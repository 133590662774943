<template>
  <section class="carusel2">
    <div class="carusel2__wave wave__top"></div>

    <div class="carusel2-wrapper container">
      <h1>SJOKOLADEARKITEKTER</h1>
      <Carousel
        :per-page="1"
        :scroll-per-page="false"
        :navigation-enabled="true"
        :pagination-enabled="false"
        :navigation-next-label="nextLabel"
        :navigation-prev-label="prevLabel"
        :mouse-drag="!isDesktop"
        :touch-drag="true"
        :loop="true"
        :autoplay="false"
        :autoplayTimeout="2000"
        @page-change="onPageChange"
        :navigateTo="[navigateTo, true]"
      >
        <template v-if="isDesktop">
          <Slide>
            <section class="carusel2 container">
              <div class="carusel2__content">
                <img
                  class="carusel2__content__arkitekten"
                  src="@/assets/Elements/sverre.png"
                />
                <div class="carusel2__content__subdiv">
                  <p>
                    Sverre Sætre er én av norges fremste konditor og eier av
                    Sverre Sætre Konditori på Frogner i Oslo. Han har vunnet
                    flere medaljer med det norske kokkelandslaget og er jevnlig
                    bidragsyter i både aviser og kokkeprogrammer. Sjokoladeboka
                    hans ble kåret til Årets beste norske kokebok 2019.
                  </p>
                  <div class="carusel2__content__button-parent">
                    <a href="https://www.instagram.com/sverresaetre/">
                      <img
                        class="carusel2__content__button-parent__socials"
                        src="@/assets/icons/ig_beige.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div class="carusel2__content__button-parent__mobile">
                <a href="https://www.instagram.com/sverresaetre/">
                  <img
                    class="carusel2__content__button-parent__socials"
                    src="@/assets/icons/ig_beige.svg"
                  />
                </a>
              </div>
            </section>
          </Slide>

          <Slide>
            <section class="carusel2 container">
              <div class="carusel2__content">
                <img
                  class="carusel2__content__arkitekten"
                  src="@/assets/Elements/holtungen.png"
                />
                <div class="carusel2__content__subdiv">
                  <p>
                    Tonje Holtungen, også kjent som sjokoladearkitekten, er en
                    av Norges beste konditor som er spesialist på sjokolade.
                    Tonje er én av mesterhjernene bak oppskriftene på
                    sjokoladehusene, og har lagt inn smarte tips og triks for at
                    byggeprosessen skal gå så knirkefritt som overhodet mulig.
                  </p>
                  <div class="carusel2__content__button-parent">
                    <a href="http://www.facebook.com/Sjokolade-Salongen">
                      <img
                        class="carusel2__content__button-parent__socials"
                        src="@/assets/icons/fb_beige.svg"
                      />
                    </a>

                    <a href="http://instagram.com/sjokoladesalongen">
                      <img
                        class="carusel2__content__button-parent__socials"
                        src="@/assets/icons/ig_beige.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div class="carusel2__content__button-parent__mobile">
                <a href="http://www.facebook.com/Sjokolade-Salongen">
                  <img
                    class="carusel2__content__button-parent__socials"
                    src="@/assets/icons/fb_beige.svg"
                  />
                </a>

                <a href="http://instagram.com/sjokoladesalongen">
                  <img
                    class="carusel2__content__button-parent__socials"
                    src="@/assets/icons/ig_beige.svg"
                  />
                </a>
              </div>
            </section>
          </Slide>
        </template>
      </Carousel>

      <template v-if="!isDesktop">
        <div class="carusel2__button-wrapper">
          <ButtonComponent
            :label="'TONJE HOLTUNGEN'"
            tag="button"
            @click="showTonje = true"
            theme="primary"
            :class="{
              'carusel2__button-wrapper--selected': showTonje,
            }"
          />

          <ButtonComponent
            :label="'SVERRE SÆTRE'"
            tag="button"
            @click="showTonje = false"
            theme="primary"
            :class="{
              'carusel2__button-wrapper--selected': !showTonje,
            }"
          />
        </div>

        <section v-if="!showTonje" class="carusel2 container">
          <div class="carusel2__content">
            <img
              class="carusel2__content__arkitekten"
              src="@/assets/Elements/sverre.png"
            />
            <div class="carusel2__content__subdiv">
              <p>
                Sverre Sætre er én av norges fremste konditor og eier av Sverre
                Sætre Konditori på Frogner i Oslo. Han har vunnet flere medaljer
                med det norske kokkelandslaget og er jevnlig bidragsyter i både
                aviser og kokkeprogrammer. Sjokoladeboka hans ble kåret til
                Årets beste norske kokebok 2019.
              </p>
              <div class="carusel2__content__button-parent">
                <a href="https://www.instagram.com/sverresaetre/">
                  <img
                    class="carusel2__content__button-parent__socials"
                    src="@/assets/icons/ig_beige.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section v-if="showTonje" class="carusel2 container">
          <div class="carusel2__content">
            <img
              class="carusel2__content__arkitekten"
              src="@/assets/Elements/holtungen.png"
            />
            <div class="carusel2__content__subdiv">
              <p>
                Tonje Holtungen, også kjent som sjokoladearkitekten, er en av
                Norges beste konditor som er spesialist på sjokolade. Tonje er
                én av mesterhjernene bak oppskriftene på sjokoladehusene, og har
                lagt inn smarte tips og triks for at byggeprosessen skal gå så
                knirkefritt som overhodet mulig.
              </p>
              <div class="carusel2__content__button-parent">
                <a href="http://www.facebook.com/Sjokolade-Salongen">
                  <img
                    class="carusel2__content__button-parent__socials"
                    src="@/assets/icons/fb_beige.svg"
                  />
                </a>

                <a href="http://instagram.com/sjokoladesalongen">
                  <img
                    class="carusel2__content__button-parent__socials"
                    src="@/assets/icons/ig_beige.svg"
                  />
                </a>
              </div>
            </div>
          </div>
          <!-- <div class="carusel2__content__button-parent__mobile">
            <a href="http://www.facebook.com/Sjokolade-Salongen">
              <img
                class="carusel2__content__button-parent__socials"
                src="@/assets/icons/fb_beige.svg"
              />
            </a>

            <a href="http://instagram.com/sjokoladesalongen">
              <img
                class="carusel2__content__button-parent__socials"
                src="@/assets/icons/ig_beige.svg"
              />
            </a>
          </div> -->
        </section>
      </template>
    </div>
  </section>
</template>

<script>
import { redirect as Redirect } from "@/utils";
import { Slide, Carousel } from "vue-carousel";

export default {
  props: {
    houses: Array,
    isCampaign: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showTonje: true,
      navigateTo: 0,
      currentSlide: 1,
      nextLabel:
        '<svg width="34" height="59" viewBox="0 0 34 59" fill="#fff8e2" xmlns="http://www.w3.org/2000/svg"> <path d="M32.5783 32.9716C34.4752 31.0518 34.4752 27.934 32.5783 26.0142L8.29801 1.44043C6.40111 -0.479393 3.32055 -0.479393 1.42365 1.44043C-0.473248 3.36025 -0.473248 6.47804 1.42365 8.39787L22.2744 29.5006L1.43731 50.6033C-0.45959 52.5231 -0.45959 55.6393 1.43731 57.5592C3.33421 59.479 6.41477 59.479 8.31167 57.5592L32.592 32.9854L32.5768 32.9701L32.5783 32.9716Z" fill="#3B1905"/></svg>',
      prevLabel:
        '<svg width="34" height="59" viewBox="0 0 34 59" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.4217 26.0284C-0.475194 27.9482 -0.475194 31.066 1.4217 32.9858L25.702 57.5596C27.5989 59.4794 30.6795 59.4794 32.5764 57.5596C34.4733 55.6397 34.4733 52.522 32.5764 50.6021L11.7257 29.4994L32.5627 8.39675C34.4596 6.47692 34.4596 3.36067 32.5627 1.44084C30.6658 -0.478979 27.5852 -0.478979 25.6883 1.44084L1.40805 26.0146L1.42322 26.0299L1.4217 26.0284Z" fill="#3B1905"/></svg>',
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    isDesktop() {
      return this.windowWidth > 992 ? true : false;
    },
  },

  methods: {
    redirect(...target) {
      Redirect(...target, this)();
    },
    onPageChange(currentSlide) {
      if (isNaN(currentSlide)) return;
      let val = currentSlide + 1;

      this.currentSlide = val;

      this.navigateTo = currentSlide;

      if (this.isCampaign) return;

      let hero = this.houses[val].name;

      if (this.$route.query.helt === hero) return;

      this.$router.replace({
        path: this.$route.path,
        query: { helt: hero },
      });
    },
    redirectExternal() {
      const externalURL =
        this.currentSlide === 5 ? `hus/1` : `/hus/${this.currentSlide + 1}`;

      window.location.href = externalURL;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  components: {
    Slide,
    Carousel,
  },
};
</script>

<style lang="scss">
.carusel2 {
  position: relative;

  background-color: var(--secondary);

  padding-top: 3rem;

  &__wave {
    background-image: url("@/assets/BG/wave_yellow_desktop_top.svg");

    @include respond-below("tablet") {
      background-image: url("@/assets/BG/wave_yellow_mobile_top.svg");
    }
  }

  h1 {
    margin-bottom: 2rem;
  }
  p {
    @include respond-below("phone") {
      margin: 0;
    }
  }

  .VueCarousel {
    max-width: 1500px;
    margin: auto;

    &-wrapper {
      margin-top: 2rem;
      padding-block: 3rem 6rem;

      @include respond-below("tablet") {
        padding-block: 0rem;
      }
    }

    &-navigation {
      &-prev {
        left: 50px !important;

        @include respond-above("phone") {
          transform: translateY(calc(-75%)) translateX(-100%);
        }

        @include respond-below("tablet") {
          display: none;
        }
      }
      &-next {
        right: 50px !important;

        @include respond-above("phone") {
          transform: translateY(calc(-75%)) translateX(100%);
        }

        @include respond-below("tablet") {
          display: none;
        }
      }

      &-button {
        &:focus {
          outline: none !important;
        }
      }
    }

    &-slide {
      transition: scale 0.3s ease-in;
      -webkit-transition: scale 0.3s ease-in;
    }
  }

  &__hero {
    color: $brown;

    position: relative;

    &--active {
      scale: 1.4;
      z-index: 2;

      @include respond-below("tablet") {
        scale: 1;
      }
    }

    &-content-wrapper {
      position: absolute;
      bottom: 0rem;
      width: 100%;

      @include respond-below("tablet") {
        bottom: 4rem;
      }

      @include respond-below("phone") {
        bottom: 1rem;
      }
    }

    &-content {
      display: flex;
      justify-content: center;
    }

    &-image {
      object-fit: contain;
      object-position: center;

      border-radius: 100%;

      aspect-ratio: 1;
      width: 100%;
      height: 75%;

      max-width: 500px;
    }
    &-name {
      transition: font-size 0.5s ease;
      -webkit-transition: font-size 0.5s ease;
      margin: 0;
      width: initial;
      white-space: nowrap;
      padding: 0.25rem 0.5rem;
      font-size: 26px;
      font-weight: 700;
    }
    &-subtitle {
      transition: font-size 0.5s ease;
      -webkit-transition: font-size 0.5s ease;

      margin: 0;
      white-space: nowrap;
      font-family: "FS ME";
      padding: 0.4em 1em;
      font-size: 14px;
      width: fit-content;
      font-weight: 400;
      margin-top: -1px;
    }
  }
}

.carusel2 {
  max-width: none;
  padding-bottom: 1rem;
  background-color: var(--secondary);

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;

    @include respond-below("tablet") {
      gap: 3rem;
      display: flex;
      flex-direction: column;

      align-items: center;

      p {
        text-align: center;
      }
    }

    &__arkitekten {
      width: 375px;

      border: solid;
      border-color: $white;
      border-width: 5px;

      @include respond-below("phone") {
        width: 250px;

        gap: 2rem;
      }
    }

    p {
      max-width: 750px;
      margin: 0;
      text-align: start;
      text-align: center;
    }

    &__button-parent {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 1rem;
      margin-top: 2rem;

      @include respond-below("tablet") {
        justify-content: center;
      }

      &__socials {
        width: 30px;

        @include respond-below("tablet") {
          width: 60px;
        }
      }

      &__mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
        gap: 1.5rem;
        @include respond-above("phone") {
          display: none;
        }
      }
    }

    &__subdiv {
      p {
        text-align: left;

        @include respond-below("tablet") {
          text-align: center;
        }
      }
    }
  }

  &__button-wrapper {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: fit-content;

    justify-content: center;
    gap: 1.5rem;

    .elder-button--primary {
      background-color: $brown;
      border-color: $brown;
      border-width: 2px;
    }

    &--selected {
      background-color: var(--secondary) !important;

      span {
        color: $brown;
      }
    }
  }
}
</style>
