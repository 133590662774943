<template>
  <section class="ChooseHouse container" id="premie">
    <div class="ChooseHouse__difficulty">
      <div
        @click="difficultyLevel = 'all'"
        class="ChooseHouse__difficulty-button"
        :class="{
          'ChooseHouse__difficulty-button--selected': difficultyLevel === 'all',
        }"
      >
        <span>ALLE</span>
      </div>
      <div
        @click="difficultyLevel = 'easy'"
        class="ChooseHouse__difficulty-button"
        :class="{
          'ChooseHouse__difficulty-button--selected':
            difficultyLevel === 'easy',
        }"
      >
        <span>LETT</span>
      </div>
      <div
        @click="difficultyLevel = 'medium'"
        class="ChooseHouse__difficulty-button"
        :class="{
          'ChooseHouse__difficulty-button--selected':
            difficultyLevel === 'medium',
        }"
      >
        <span>MIDDELS</span>
      </div>
      <div
        @click="difficultyLevel = 'hard'"
        class="ChooseHouse__difficulty-button"
        :class="{
          'ChooseHouse__difficulty-button--selected':
            difficultyLevel === 'hard',
        }"
      >
        <span>VANSKELIG</span>
      </div>
    </div>

    <div class="ChooseHouse__content container">
      <div
        v-for="(item, index) in houses"
        v-if="
          difficultyLevel === 'all' || difficultyLevel === item.difficultyLevel
        "
        :key="index"
        class="ChooseHouse__item"
      >
        <div class="ChooseHouse__item-image"><img :src="item.img" /></div>
        <div class="ChooseHouse__item-content">
          <div class="ChooseHouse__item-content__text">
            <h2>{{ item.name }}</h2>
            <p class="ChooseHouse__item-description">{{ item.description }}</p>
            <div class="ChooseHouse__points">
              <p><strong>Vanskelighetsgrad</strong></p>
              <img width="150px" :src="`/assets/icons/${item.difficulty}`" />
            </div>
          </div>
          <ButtonComponent
            :label="'INSTRUKSJONER'"
            tag="button"
            @click="redirectExternal(index + 2)"
            theme="primary"
            class="ChooseHouse__button"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Laftehytta from "@/assets/Husene/Laftehytta.png";
import Julelavvo from "@/assets/Husene/Julelavvo.png";
import Stabburet from "@/assets/Husene/Stabburet.png";
import Rødlykke from "@/assets/Husene/Rødlykke.png";
import Fabrikken from "@/assets/Husene/Fabrikk.png";
import Kvikklunsjtre from "@/assets/Husene/Kvikklunsjtre.png";
import Sjokoladehus from "@/assets/Husene/Sjokoladehus.png";

export default {
  data() {
    return {
      difficultyLevel: "all",
      houses: [
        {
          img: Laftehytta,
          name: "LAFTEHYTTA",
          description:
            "I Norge har vi laftet hus og hytter i århundrer. Mestrer du den tradisjonsrike laftekunsten?",
          difficulty: "three.svg",
          difficultyLevel: "medium",
        },
        {
          img: Rødlykke,
          name: "RØD LYKKE",
          description:
            "Et hus til å spise opp! Pynt huset, spis litt Non Stop, pynt videre, spis litt til. Det er tross alt jul.",
          difficulty: "two.svg",
          difficultyLevel: "easy",
        },
        {
          img: Julelavvo,
          name: "JULELAVVO",
          description:
            "Sett opp lavvoen og hold varmen i julen med dine nærmeste.",
          difficulty: "two.svg",
          difficultyLevel: "easy",
        },
        {
          img: Fabrikken,
          name: "FREIA-FABRIKKEN",
          description:
            "Klarer du å bygge den ikoniske Freia-Fabrikken? Vi utfordrer deg!",
          difficulty: "five.svg",
          difficultyLevel: "hard",
        },
        {
          img: Sjokoladehus,
          name: "SJOKOLADEHUS",
          description:
            "Hva med å bygge et tradisjonelt sjokoladehus for å komme i skikkelig julestemning?",
          difficulty: "four.svg",
          difficultyLevel: "hard",
        },
        {
          img: Kvikklunsjtre,
          name: "KVIKK LUNSJ TRE",
          description: "Hvor rask er du? Lag et kvikt tre på 1-2-3!",
          difficulty: "one.svg",
          difficultyLevel: "easy",
        },
        {
          img: Stabburet,
          name: "STABBURET",
          description: "Et lite eventyr av et hus. Tar du utfordringen?",
          difficulty: "three.svg",
          difficultyLevel: "medium",
        },
      ],
    };
  },

  methods: {
    redirectExternal(index) {
      const externalURL = `hus/${index}`;
      window.location.href = externalURL;
    },
  },
};
</script>

<style lang="scss">
.ChooseHouse {
  padding-top: 8rem;
  padding-bottom: 6rem;
  max-width: 1400px;

  &__difficulty {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__difficulty-button {
    width: 200px;
    height: 50px;

    cursor: pointer;

    @include respond-below("tablet") {
      width: 150px;
    }

    span {
      font-size: 1.5rem;
      line-height: 19px;
    }

    &--selected {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 10px;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;
    padding-top: 2rem;

    position: relative;

    @media (max-width: 1336px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;

    &-image {
      img {
        width: 100%;
        max-width: 510px;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: 1rem;

      @media (min-width: 1336px) {
        align-items: start;
      }

      p,
      h2 {
        margin: 0;
        margin-bottom: 1em;
        text-align: left;
        max-width: 500px;

        @media (max-width: 1336px) {
          text-align: center;
        }
      }

      &__text {
        @media (max-width: 1336px) {
          padding: 0 1rem;

          h3 {
            text-align: center;
          }

          p {
            text-align: center;
            font-size: 1rem;
            line-height: 25px;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }

  &__points {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 1.5rem;

    p {
      margin-bottom: 0;
    }

    @media (max-width: 1336px) {
      flex-direction: column;
    }
  }
}
</style>
