import isEAN from 'validator/lib/isEAN'

export default Validator => {
  Validator.register(
    'ean',
    function(value = '') {
      return isEAN(value.toString().replace(/\s/g, ''))
    },
    ':attribute ikke et gyldig nummer',
  )
}
